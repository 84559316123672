import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { noop } from 'libs';
import { Icon } from 'components/common';
import StaleComparedNotification from './StaleComparedNotification';

const CompareMenu = ({
  compareButtonLink = { pathname: '/rates/compare', state: { from: 'rates' } },
  comparisonLimit,
  onClearAll,
  onRemove,
  products = [],
  track = noop,
  visible = false,
}) => (
  <div className={classnames('compare-menu__container', { open: visible })} data-testid="compare-menu">
    <CompareMenuList
      compareButtonLink={compareButtonLink}
      comparisonLimit={comparisonLimit}
      onClearAll={onClearAll}
      onRemove={onRemove}
      products={products}
      track={track}
    />
  </div>
);
CompareMenu.propTypes = {
  compareButtonLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  comparisonLimit: PropTypes.number.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  visible: PropTypes.bool,
  track: PropTypes.func.isRequired,
};

const CompareMenuList = ({ compareButtonLink, comparisonLimit, onClearAll, products = [], onRemove, track }) => (
  <div className="compare-menu">
    <h1 className="compare-menu__heading">Selected Products (MAX. {comparisonLimit})</h1>
    <StaleComparedNotification />
    <div className="compare-menu__items">
      {products.map((product) => (
        <CompareItem key={product.id} onRemove={onRemove} product={product} />
      ))}
    </div>
    {products.length > 0 && (
      <button
        className="compare-menu__clear-all-button"
        data-testid="compare-menu-clear-all-button"
        onClick={onClearAll}
        type="button"
      >
        Remove all
      </button>
    )}
    <div className="compare-menu__footer">
      <Link
        className={classnames('btn compare-menu__footer-button', {
          disabled: !products.length,
        })}
        data-testid="compare-products-button"
        onClick={() => track(products.length)}
        to={compareButtonLink}
      >
        {products.length ? `Compare ${pluralize('Product', products.length, true)}` : 'Compare'}
      </Link>
    </div>
  </div>
);
CompareMenuList.propTypes = {
  compareButtonLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  comparisonLimit: PropTypes.number.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  track: PropTypes.func.isRequired,
};

const CompareItem = ({ product, onRemove }) => {
  const logoPath = product.lender.logo_path || '/images/spotlight-logo-200x200.png';
  const rate = product.rate.rate ?? product.rate;

  return (
    <div className="compare-menu__item" data-testid="compare-menu-item">
      <img className="compare-menu__item-logo" src={logoPath} alt={product.lender.name} />
      <div>
        <div className="compare-menu__item-heading">{product.lender.name}</div>
        <div className="compare-menu__item-sub-heading">{product.name}</div>
        <div className="compare-menu__item-rate">{`${parseFloat(rate).toFixed(2)}%`}</div>
      </div>
      <button
        aria-label="Remove from comparison"
        className="compare-menu__item-close"
        data-testid="compare-menu-item-remove-button"
        onClick={() => onRemove(product)}
        type="button"
      >
        <Icon name="xmark" />
      </button>
    </div>
  );
};
CompareItem.propTypes = {
  product: PropTypes.shape({
    lender: PropTypes.shape({
      logo_path: PropTypes.string,
      name: PropTypes.string,
    }),
    name: PropTypes.string,
    rate: PropTypes.oneOfType([
      PropTypes.shape({
        rate: PropTypes.number,
      }),
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default CompareMenu;
